.home {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #f2f5f7;
}

.invitationCont {
  width: 100vw;
}

.nameCont {
  font-family: "Lobster", cursive;
  font-size: 6rem;
}

.andCont {
  font-family: "Lobster", cursive;
  font-size: 7rem;
  color: #c9b375;
}

.invitationNameCont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invitationText {
  color: #c9b375;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 2rem 0 1rem 0;
  font-family: 'Rubik';
}

.dateCont {
  font-family: "Lobster", cursive;
  font-size: 2rem;
  color: #c9b375;
  width: 100%;
  text-align: center;
  padding: 2rem 0 2rem 0;
}

.timerContList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2vw;
}

.timerCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 20vw;
  background-color: #eae3d0;
  border-radius: 10px;
  font-family: "Lobster", cursive;
  font-size: 1.2rem;
}

.timerVal {
  font-size: 2rem;
  color: #a18f5d;
}

.detailsCont {
  padding: 20px 0;
  width: 100vw;
  overflow: hidden;
}

.groomScrollCont {
  animation: 1s ease-out 0s 1 slideInRight;
}

.brideScrollCont {
  animation: 1s ease-out 0s 1 slideInLeft;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

.howWeMetCont {
  padding: 0 0 20px 0;
}

.flexCont {
  display: flex;
  align-items: center;
}

.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footerCont {
  width: 88%;
  display: flex;
  justify-content: space-between;
  padding: 4% 6%;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 48%;
  font-family: 'Rubik';
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 1px 1px 1px grey;
}

.uberBtn {
  background-color: #000000;
  color: #ffffff;
}

.olaBtn {
  background-color: #ffffff;
}

.mapsBtn {
  width: 88%;
  margin: 4% 6% 0;
  background-color: #ffffff;
}

.olaImg {
  width: 40px;
  margin-right: 4px;
}

.soloImg {
  width: 50vw;
  padding-right: 10px;
}

.soloImg2 {
  width: 50vw;
  padding-left: 10px;
}

.aboutTextCont {
  width: 50vw;
  padding: 0 10px;
  font-size: 12px;
  overflow: hidden;
}

.textRight {
  text-align: right;
}

.howWeMetText {
  padding: 0 10px;
  font-size: 14px;
}

.textCont {
  font-size: 1.5rem;
  font-family: 'Rubik';
  font-weight: 600;
  padding: 10px;
  color: #a18f5d;
}

.venueCont {
  margin: 6%;
  text-align: center;
}

.venue-text-1 {
  font-size: 1.2rem;
  font-weight: 400;
  padding-bottom: 1rem;
  opacity: 0.6;
  font-family: 'Rubik';
}

.venue-text-2 {
  font-family: "Lobster", cursive;
  font-size: 2rem;
  color: #a18f5d;
}

.venue-text-3 {
  font-family: "Rubik";
  font-size: 1.2rem;
  color: #a18f5d;
}

#slideshow {
  width:100vw;
  overflow:hidden;
}

.container {
  width:1000vw; /* 100 x 10 */
  position:relative;
  -webkit-animation:slide 28s ease-in-out infinite;
  -moz-animation:slide 28s ease-in-out infinite;
  -ms-animation:slide 28s ease-in-out infinite;
  -o-animation:slide 28s ease-in-out infinite;
  animation:slide 28s ease-in-out infinite;
}

.container img {
  width:100vw;
  height:300px;
  float:left;
}

@-webkit-keyframes slide {
  0% {left:0;}
  10% {left:-100vw;}
  20% {left:-200vw;}
  30% {left:-300vw;}
  40% {left:-400vw;}
  50% {left:-500vw;}
  60% {left:-600vw;}
  70% {left:-700vw;}
  80% {left:-800vw;}
  90% {left:-900vw;}
  100% {left:0;}
}
@-moz-keyframes slide {
  0% {left:0;}
  10% {left:-100vw;}
  20% {left:-200vw;}
  30% {left:-300vw;}
  40% {left:-400vw;}
  50% {left:-500vw;}
  60% {left:-600vw;}
  70% {left:-700vw;}
  80% {left:-800vw;}
  90% {left:-900vw;}
  100% {left:0;}
}
@-ms-keyframes slide {
  0% {left:0;}
  10% {left:-100vw;}
  20% {left:-200vw;}
  30% {left:-300vw;}
  40% {left:-400vw;}
  50% {left:-500vw;}
  60% {left:-600vw;}
  70% {left:-700vw;}
  80% {left:-800vw;}
  90% {left:-900vw;}
  100% {left:0;}
}
@-o-keyframes slide {
  0% {left:0;}
  10% {left:-100vw;}
  20% {left:-200vw;}
  30% {left:-300vw;}
  40% {left:-400vw;}
  50% {left:-500vw;}
  60% {left:-600vw;}
  70% {left:-700vw;}
  80% {left:-800vw;}
  90% {left:-900vw;}
  100% {left:0;}
}
@keyframes slide {
  0% {left:0;}
  10% {left:-100vw;}
  20% {left:-200vw;}
  30% {left:-300vw;}
  40% {left:-400vw;}
  50% {left:-500vw;}
  60% {left:-600vw;}
  70% {left:-700vw;}
  80% {left:-800vw;}
  90% {left:-900vw;}
  100% {left:0;}
}




